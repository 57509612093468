import React, { useState } from "react";
import { sanitize } from 'dompurify';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { CLATooltip, CLALinkPanel, CLASimpleDrawer } from "@ais/components";
import { FORM_SETTINGS } from '@ais/constants';
import { Flex } from "@ais/palette";
import { colors } from '@ais/theme';
import { VFSectionV2, VFInnerSection } from "@components/FormView/v2/Section";
import { ShareFormButton } from '@components/ShareForm';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { useFinalizedProject } from '@hooks/useProject';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { TailorProcedureProvider } from "@components/Forms/state"
import { ProcedureContextProvider } from '@contexts/Procedures';
import { TailorProceduresDrawerProvider } from '@contexts/TailorProcedures/TailorProceduresDrawerContext';
import ClientAccessModal from '@views/ProjectFormInstance/components/ClientAccess/ClientAccessModal';
import ConcurrentUsersBanner from "../components/Concurrency/ConcurrentUsersBanner";

import styles from './ProjectFormInstanceV2.module.css';

const ProjectFormInstanceV2 = () => {
    const {
        projectForm, relatedFields, userId, actions: { onLinkClick }
    } = useProjectFormInstanceProviderV2();
    const { CLIENT_ACCESS_MODAL: { MODE } } = FORM_SETTINGS.EN;
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);

    const [isSharedToClient, setIsSharedToClient] = useState(!(projectForm && projectForm.clientProjectFormStatusId === null));
    const [shareClientModalVisble, setShareClientModalVisble] = useState({ visible: false, mode: "" })

    const handleShareClientClick = () => {
        setShareClientModalVisble({ visible: true, mode: MODE.SHARE })
    };

    const handleRemoveSharedClientClick = () => {
        setShareClientModalVisble({ visible: true, mode: MODE.REMOVE_SHARE })
    };

    return (
        <div className={styles['project-form-instance']}>
            <Grid container gap="29px">
                {projectForm?.units && (
                    <Grid container gap="8px" wrap="nowrap">
                        <Grid item>
                            <div className={styles['vf-units-label']}>
                                {PROJECT_FORM_INSTANCE.EN.ASSIGNED_UNITS}
                            </div>
                        </Grid>
                        <Grid>
                            <div className={styles['vf-units']}>
                                {projectForm?.units
                                    .map((item) => item.ProjectUnitTitle)
                                    .join(' | ')}
                            </div>
                        </Grid>
                    </Grid>
                )}
                <Grid container gap="8px">
                    <Grid item xs={12}>
                        <Flex direction="row" justify="between">
                            <div className={styles['vf-form-title']}>
                                {projectForm?.projectFormName}
                            </div>
                            <ShareFormButton
                                isSharedToClient={isSharedToClient}
                                isProjectFinalized={isProjectFinalized}
                                projectForm={projectForm}
                                onRemoveSharedClientClick={handleRemoveSharedClientClick}
                                onShareClientClick={handleShareClientClick}
                            />
                        </Flex>
                    </Grid>
                    {projectForm?.toolTipText && (
                        <Grid>
                            <CLATooltip
                                title={
                                    <div
                                        className="ql-editor"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitize(projectForm?.toolTipText, {
                                                ADD_ATTR: ['target'],
                                            }),
                                        }}
                                    />
                                }
                                placement="bottom"
                            >
                                <HelpIcon style={{ fontSize: '18px', color: colors.navy[400] }} />
                            </CLATooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {
                projectForm?.formDescription && (
                    <div className={styles['vf-form-description']}>
                        <span>{projectForm?.formDescription}</span>
                    </div>
                )
            }
            <TailorProcedureProvider>
                <ProcedureContextProvider>
                    <TailorProceduresDrawerProvider>
                        {projectForm && projectForm.schema && projectForm.schema.map((section, index) => {

                            //use isHideComponent in NATGFieldSelector for rules checking

                            return (
                                <VFSectionV2 key={index} section={section}>
                                    <VFInnerSection fields={section.fields} auditAreaId={section?.auditArea ?? null} sectionId={section.id} />
                                </VFSectionV2>
                            )
                        })
                        }
                    </TailorProceduresDrawerProvider>
                </ProcedureContextProvider>
            </TailorProcedureProvider>
            <ConcurrentUsersBanner userId={userId} />
            <CLASimpleDrawer
                anchor="right"
                isVisible={relatedFields.length > 0}
                onClose={onLinkClick}
                allowBackDropCloseClick
            >
                <CLALinkPanel
                    fields={relatedFields}
                    title="Linked Fields"
                    projectId={projectId}
                    isV2 // to update
                //disable for signedoff
                />
            </CLASimpleDrawer>
            {shareClientModalVisble.visible &&
                <ClientAccessModal
                    visible={shareClientModalVisble}
                    setShareClientModalVisble={setShareClientModalVisble}
                    projectForm={projectForm}
                    projectId={projectId}
                    setSharedToClient={setIsSharedToClient}
                />
            }
        </div>
    )
}

export default React.memo(ProjectFormInstanceV2);