import React, { useState, useEffect, useMemo } from 'react'
import styles from './VFSectionV2.module.css';
import { IconButton, Collapse } from '@mui/material';
import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ProcedureHeader } from './ProcedureHeader/ProcedureHeader';
import { VFSectionV2Description } from './VFSectionV2Description'
import { VFSectionV2Tooltip } from './VFSectionV2Tooltip';
import { useQuery, useScroll } from '@hooks/index';
import string from "@utilities/stringHelper";
import useRules from '../hooks/useRules';
import { useProjectFormInstanceProviderV2 } from '@providers';

const VFSectionV2 = (props) => {
  const { projectForm, answerList } = useProjectFormInstanceProviderV2(); 
  const frameworks = useMemo(() => (
    projectForm.frameworks ? projectForm.frameworks.map(f => f.FrameworkId) : []
  ), [projectForm.frameworks]);

  const performanceStandards = useMemo(() => (
    projectForm.performanceStandards ? projectForm.performanceStandards.map(ps => ps.PerformanceStandardId) : []
  ), [projectForm.performanceStandards]);

  const { section, children } = props
  const query = useQuery()
  const [isExpanded, setIsExpanded] = useState(!section?.showCollapsed)
  const [executeScroll, elRef] = useScroll()
  const sectionId = query.get('sectionId')
  const questionIds = query.get('questionIds')
  const hasSingleQuestion = !questionIds || questionIds.split(',').length <= 1
  const shouldScrollToSection = string.toLowerCaseTrim(section.id) === string.toLowerCaseTrim(sectionId) && hasSingleQuestion;
  const { shouldDisplay } = useRules(section.rules, answerList, projectForm.methodologyIndustries, frameworks, performanceStandards);
  useEffect(() => {
    if (shouldScrollToSection) executeScroll()
  }, [])

  if (!shouldDisplay) return <></>;

  return (
    <div className={styles['vf-section-wrapper']}>
      <div ref={elRef} className={styles['vf-section']}>
        <div className={styles['section-header-wrapper']}>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
          </IconButton>
          <div className={styles['section-header']}>
            {section?.title}
            <VFSectionV2Tooltip tooltip={section.tooltip} />
          </div>
          {/* RiskAlert Icon */}
          {/* DeficiencyInfo Icon */}
          {/* TODO : Ellipsis */}
          {
            section?.type === 'PROCEDURE' ?
              <ProcedureHeader section={section} />
              :
              <IconButton disabled>
                <MoreVertIcon />
              </IconButton>
          } 
        </div>
        <VFSectionV2Description description={section.description} />
        <Collapse in={isExpanded} collapsedSize={0}>
          {children}
        </Collapse>
      </div>
    </div>
  )
}

export default React.memo(VFSectionV2)