import React, { useContext } from 'react';
import {
    Autocomplete,
    TextField,
    InputAdornment
} from '@mui/material';

import { ReactComponent as Filter } from '@assets/icon_filter.svg';

import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { useQuery } from '@hooks/index';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { useProceduresContext } from '@contexts/Procedures';
import { TailorProceduresDrawerContext } from '@contexts/TailorProcedures/TailorProceduresDrawerContext';

import TailorProcedureButton from './TailorProcedureButton';

export const ProcedureHeader = ({ section }) => {
    const { PROCEDURE: { FILTER_OPTIONS } } = PROJECT_FORM_INSTANCE
    const filterOptions = [
        FILTER_OPTIONS.OPEN_STEPS,
        FILTER_OPTIONS.COMPLETED_STEPS,
        FILTER_OPTIONS.CLIENT_INQUIRY,
        FILTER_OPTIONS.PBC_NEEDED,
        FILTER_OPTIONS.CONFIRMATION,
        FILTER_OPTIONS.PLANNING,
        FILTER_OPTIONS.INTERIM,
        FILTER_OPTIONS.YEAR_END
    ]
    const { projectForm: { methodologyVersionId } } = useProjectFormInstanceProviderV2();
    const { procedureFilter, setProcedureFilter } = useProceduresContext();
    const { setShowProcedureDrawer } = useContext(TailorProceduresDrawerContext); 
    const sectionId = section.id
    return (
        <>
            <Autocomplete
                id="size-small-standard"
                size="small"
                options={filterOptions}
                onChange={(_, values) => {
                    setProcedureFilter((prevProcedureFilter) => ({
                        ...prevProcedureFilter,
                        [`${sectionId}`]: values
                    }));
                }}
                value={procedureFilter[`${sectionId}`] ?? null}
                sx={{ width: '20%', paddingRight: '20px' }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: <InputAdornment position="start"><Filter />Filter</InputAdornment>,
                        }}
                    />
                )}
            />

            <TailorProcedureButton
                onClick={() => {
                    setShowProcedureDrawer({
                        auditArea: {
                            id: section?.auditArea ?? null,
                            auditAreaName: section?.auditAreaName ?? null,
                            methodologyVersionId: methodologyVersionId
                        },
                        show: true,
                        sectionId: sectionId ?? null
                    })
                }}
                sectionId={sectionId}
            />
        </>
    )
}
