import React, { memo } from 'react'
import { styled, Box } from '@mui/material'
import QuestionGroupActions from './QuestionGroupActions'
import { useFormContext, useFieldArray } from 'react-hook-form';
import { FIELD_TYPES } from '@ais/constants'
const Container = styled('div')({
  boxSizing: 'border-box',
  width: '100%',
  minHeight: '15vh',
  padding: '4px 16px'
})
const QuestionGroupInner = (props) => {
  const {
    disabled,
    clientExternalIcon,
    onSubmit,
    field: {
      id,
      fields : origFields,
      editableByClient,
      visibleToClient
    },
    fieldWrapper: FieldWrapper
  } = props
  const { control } = useFormContext();
  const fieldArrayMethods = useFieldArray({
    control,
    name: id
  });
  const {fields} = fieldArrayMethods
  const changeKeyFields = (keys, qgroupIndex) => {
    let ctr = 0;
    return origFields.map(row=> 
      row.map(field => {
        const key = `${id}.${qgroupIndex}.${Object.keys(keys)[ctr]}`
        ctr++
        return {
          ...field,
          id: key,
        }
      })
    )
  }

  const updateExternalProps = mappedFields => {
    mappedFields.forEach(row => {
      row.forEach(field => {
        field.editableByClient = editableByClient;
        field.visibleToClient = visibleToClient;
      });
    });
  }

  return (
    <Container>
      <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
        {clientExternalIcon && clientExternalIcon}
      </Box>
      {fields.map((field, index) => {
        const baseFields = origFields.reduce((acc, row) => {
          row.forEach(item => { 
            if(item && item.id && !([FIELD_TYPES.TABLE, FIELD_TYPES.DROPDOWN].includes(item.type))) acc[item.id] = item.defaultValue
            else if(item && item.id && item.type === FIELD_TYPES.DROPDOWN && item.allowOtherOption && item.allowMultiSelect) acc[item.id] = item.defaultValue !== '' ? item.defaultValue :  []
            else if(item && item.id && item.type === FIELD_TYPES.TABLE) {
              const fieldIds = item.columns.reduce((acc, curr)=>{
                let value = ''
                if(curr.columnFieldType === 'checkbox') value = curr.checkboxCheckedByDefault
                if(curr.columnFieldType === 'radioButton') value = curr.radioDefaultValue
                if(curr.columnFieldType === 'dropdown') value = curr.dropdownDefaultValue
                acc[curr.id] = value
                return acc
              },{})
              acc[item.id] = item.rows.map(row => {
                return {
                  ...row,
                  values:fieldIds
                }
              })
            }
            else acc[item.id] = item.defaultValue
          })
          return acc;
        },{})
        let mappedFields = [] 
        if(Object.keys(field).filter(key => key !== 'id').length !== Object.keys(baseFields).length || Object.keys(baseFields).includes(Object.keys(field)[0]))
          mappedFields = changeKeyFields(baseFields, index)
        else
          mappedFields = changeKeyFields(field, index);

        updateExternalProps(mappedFields);

        return(
          <div key={`questionGroup-${id}-${index}`}>
            {index > 0 && <Box as="hr" mx="25px" />}
            <FieldWrapper fields={mappedFields} handleSubmit={onSubmit}/>
            <QuestionGroupActions 
              index={index} 
              baseFields={baseFields} 
              disabled={fields.length <= 1 || disabled} 
              id={id}
              fieldArrayMethods={fieldArrayMethods}
              onSubmit={onSubmit}
            />
          </div>
        )
      })}
    </Container>
  )
}

export default memo(QuestionGroupInner)