export const transformAnswers = (answers)=> {
    const ans = answers.reduce((map, obj) => {
        if(obj?.questionId)
            map[obj.questionId.toLowerCase()] = obj.answer
        else
            map[obj.id] = obj.value
        return map;
    }, {});
    
    const newTransformedAnswer = {} 

    for (const key in ans) { 
        if(Array.isArray(ans[key]) && ans[key].some(v => typeof v === 'object' && v !== null)) {
            if(ans[key].some(v => typeof v === 'object' && 'rowLabel' in v)){
                newTransformedAnswer[key] = ans[key].map(item => {
                    const newObj = {
                        rowId: item.id,
                        rowLabel: item.rowLabel,
                    };

                    if(item.hasOwnProperty('values')) item.values.forEach(value => {
                        newObj[value.id] = value.value
                    })

                    return newObj
                })
            } 
            else {
                const qgrpAnswersArray = []
                ans[key].forEach((qgrpObj, qIndex) => {
                    qgrpAnswersArray[qIndex] = transformAnswers(qgrpObj)
                })
                newTransformedAnswer[key] = qgrpAnswersArray

            }
        } 
        else newTransformedAnswer[key] = ans[key]
    }
    return newTransformedAnswer
};

export const transformAnswersToPayload = (answer, isTable, isQuestionGroup) => {
    let transformedValue = answer;
    if(isTable) {
        transformedValue = answer.map((row, rowIndex) => ({
            index:rowIndex,
            id: row.rowId,
            rowLabel: row.rowLabel,
            values: Object.keys(row).filter((key) => key !== 'rowId' && key !== 'rowLabel').map((key,index) => ({
                index,
                id: key,
                value: row[key]
            })).filter(value => value.value !== undefined)
        }))
    } 
    if(isQuestionGroup) {
        transformedValue = answer.map((item)=>{
            const transformedItem = Object.keys(item).map((key, index) => {
                if(Array.isArray(item[key]) && item[key].length > 0 && item[key][0].rowId){
                    return {
                        index,
                        id: key,
                        value: item[key].map(row => ({
                            id: row.rowId,
                            rowLabel: row.rowLabel,
                            values: Object.keys(row).filter(rowKey => rowKey !== 'rowId' && rowKey !== 'rowLabel').map(rowKey => ({
                                id: rowKey,
                                value: row[rowKey]
                            }))
                        }))
                    }
                }else {
                    return {
                        index,
                        id: key,
                        value: item[key]
                    }
                } 
            })
            return transformedItem
        })
    }

    return transformedValue;
};

export const transformAnswerFromUpdate = (input)=> {
    if(Array.isArray(input)){
        if(input.some(v => typeof v === 'object')){
            if(input.some(v => 'rowLabel' in v || !Array.isArray(v))){
                return input.map(item => {
                    const newObj = {
                        rowId: item.id,
                        rowLabel: item.rowLabel
                    }
                    if(item.hasOwnProperty('values')) item.values.forEach(value => newObj[value.id] = value.value)
                    return newObj
                })
            } else {
                return input.map(innerArray => {
                    return transformAnswers(innerArray)
                })
            }
        } else return input
    } else {
        return input
    }
}
